import { useState, useEffect } from 'react';
import styles from './share.module.less';
import ShareMusic from './ShareMusic/ShareMusic';
import ShareCode from './ShareCode/ShareCode';
import ShareCyberAvatar from './ShareCyberAvatar/ShareCyberAvatar';
import BasicSharePage from './BasicSharePage/BasicSharePage';
import { getShareLink } from '@/api/userLikeObject';
import { getUserWork } from '@/api/userWork';
import { useParams } from 'react-router-dom';
import SongInfo from '@/base/ShareData/SongInfo';
import CodeInfo from '@/base/ShareData/CodeInfo';
import CyberAvatarInfo from '@/base/ShareData/CyberAvatarInfo';
import BasicSharePageInfo from '@/base/ShareData/BasicSharePageInfo';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';

// 定义 ShareInfo 类型  
type ShareInfo = SongInfo | CodeInfo | CyberAvatarInfo | BasicSharePageInfo;

const Share = () => {
    const { userInfoStore } = useStores();
    const [shareType, setShareType] = useState<string>('');
    const [shareInfo, setShareInfo] = useState<ShareInfo | null>(null);
    const { md5 } = useParams();
    const getShareInfo = async () => {
        if (!md5) {
            console.error('md5 is undefined');
            return;
        }
        try {
            let useId = -1;
            if (userInfoStore.userInfoData.id !== 0) {
                useId = userInfoStore.userInfoData.id;
            }
            const res = await getUserWork(md5, useId);
            if (res.status === "success") {
                setShareInfo(SongInfo.toCamelCaseObj(res.data.work_info));
                setShareType(res.data.work_type);
            }
        } catch (error) {
            console.error('Error getting song info:', error);
        }
    }

    useEffect(() => {
        getShareInfo();
    }, [md5]);
    return (
        <div className={styles.shareBox}>
            {shareType === 'music' && <ShareMusic songInfo={shareInfo as SongInfo} />}
            {shareType === 'code' && <ShareCode codeInfo={shareInfo as CodeInfo} />}
            {shareType === 'cyberAvatar' && <ShareCyberAvatar cyberAvatarInfo={shareInfo as CyberAvatarInfo} />}
            {shareType === 'basicPost' && <BasicSharePage basicSharePageInfo={shareInfo as BasicSharePageInfo} />}
        </div>
    )
}

export default observer(Share);
