import React, { useEffect, useState } from 'react';
import styles from './aiChat.module.less';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { createUserWork, getAllUserWorks } from '@/api/userWork';
import { useParams } from 'react-router-dom';
import AIChatRegionContent from "@/base/ElementData/AIChatRegionContent";
import DataDefinition from "@/base/DataDefinition";
import { useNavigate } from 'react-router-dom';
import ChatPage from './ChatPage/ChatPage';
import { generateMD5 } from '@/utils/utils';
import HiIcon from '@/assets/img/chat_region_hi.svg';
import RecentChatIcon from '@/assets/img/playground/chat_recent_chat.svg';
import IntelligentAgentIcon from '@/assets/img/playground/chat_intelligent_agent.svg';
import CreateAgentModal from './CreateAgentModal/CreateAgentModal';
import ModifyAgentConfig from './ModifyAgentConfig/ModifyAgentConfig';

const AIChat: React.FC = () => {
    const { md5 } = useParams();
    const navigate = useNavigate();
    const { userInfoStore } = useStores();
    const [chatHistoryList, setChatHistoryList] = useState<any[]>([]);
    const [agentList, setAgentList] = useState<any[]>([]);
    const [currentChatInfo, setCurrentChatInfo] = useState<any>({});
    const [isCreateAgentModalOpen, setIsCreateAgentModalOpen] = useState(false);
    const [isModifyAgentConfigOpen, setIsModifyAgentConfigOpen] = useState(false);

    //创建新的聊天记录
    const createNewDefaultChat = async (md5: string) => {
        const newChatAgent = {
            id: 0,
            md5: md5,
            user_id: userInfoStore.userInfoData.id,
            user_name: userInfoStore.userInfoData.name,
            work_type: "playground_chat",
            work_info: (new AIChatRegionContent({
                headerText: `你好${userInfoStore.userInfoData.name ? `,${userInfoStore.userInfoData.name}` : ""}，我是你的AI助手`,
                isStream: false,
            })).toJSON(),
            create_time: 0
        };
        const createRes: any = await createUserWork(newChatAgent);
        if (createRes.status === 'success') {
            createRes.data.work_info = new AIChatRegionContent(DataDefinition.toCamelCaseObj(createRes.data.work_info));
            return createRes.data;
        }
        throw new Error("创建聊天记录失败");
    };
    //获取所有的聊天记录
    const getAllChatHistory = async () => {
        try {
            const res: any = await getAllUserWorks(userInfoStore.userInfoData.id, "playground_chat");
            if (res.status === "success") {
                const historyItems = res.data.map((item: any) => ({
                    ...item,
                    work_info: new AIChatRegionContent(DataDefinition.toCamelCaseObj(item.work_info))
                }));
                if (historyItems.length > 0) {
                    setChatHistoryList(historyItems.reverse());
                    if (!md5) {
                        navigate(`/playground/chat/${historyItems[0].md5}`);
                        setCurrentChatInfo(historyItems[0]);
                    } else {
                        const currentChat = historyItems.find((item: any) => item.md5 === md5);
                        if (currentChat) {
                            setCurrentChatInfo(currentChat);
                        } else {
                            navigate(`/playground/chat/${historyItems[0].md5}`);
                            setCurrentChatInfo(historyItems[0]);
                        }
                    }
                } else {
                    const newChat = await createNewDefaultChat(generateMD5(`${userInfoStore.userInfoData.id}@@default_chat0`));
                    setChatHistoryList([newChat]);
                    navigate(`/playground/chat/${newChat.md5}`);
                    setCurrentChatInfo(newChat);
                }
            } else if (res.status === "error") {
                const newChat = await createNewDefaultChat(generateMD5(`${userInfoStore.userInfoData.id}@@default_chat0`));
                setChatHistoryList([newChat]);
                navigate(`/playground/chat/${newChat.md5}`);
                setCurrentChatInfo(newChat);
            }
        } catch (error) {
            console.error(error);
            // 这里可以选择显示错误提示给用户  
        }
    };

    //获取所有的智能体
    const getAllAgents = async () => {
        try {
            const res: any = await getAllUserWorks(userInfoStore.userInfoData.id, "playground_agent");
            if (res.status === "success") {
                const agentItems = res.data.map((item: any) => ({
                    ...item,
                    work_info: new AIChatRegionContent(DataDefinition.toCamelCaseObj(item.work_info))
                }));
                if (agentItems.length > 0) {
                    setAgentList(agentItems.reverse());
                    if (md5) {
                        const currentAgent = agentItems.find((item: any) => item.md5 === md5);
                        if (currentAgent) {
                            setCurrentChatInfo(currentAgent);
                        }
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleChatItemClick = (item: any) => {
        setCurrentChatInfo(item);
        navigate(`/playground/chat/${item.md5}`); // 更新 URL  
    };

    const createNewChat = async () => {
        //用最后一个聊天记录的ID+1生成md5,因为是到最前面插入，所以是chatHistoryList[0].id+1
        const newChat = await createNewDefaultChat(generateMD5(`${userInfoStore.userInfoData.id}@@default_chat${chatHistoryList[0].id + 1}`));
        setChatHistoryList([newChat, ...chatHistoryList]);
        setCurrentChatInfo(newChat);
        navigate(`/playground/chat/${newChat.md5}`);
    }

    //打开创建AI智能体的模态框
    const openCreateAgentModal = () => {
        setIsCreateAgentModalOpen(true);
    }

    const genNewAgentMd5 = () => {
        if (agentList.length === 0) {
            return generateMD5(`${userInfoStore.userInfoData.id}@@agent_chat0`);
        } else {
            return generateMD5(`${userInfoStore.userInfoData.id}@@agent_chat${agentList[0].id + 1}`);
        }
    }

    useEffect(() => {
        if (userInfoStore.userInfoData.id === 0) return;
        getAllChatHistory();
        getAllAgents();
    }, [userInfoStore.userInfoData.id]);

    return (
        <div className={styles.chatPageBox}>
            <div className={styles.sidebarBox}>
                <div className={styles.contentBox}>
                    <div className={styles.header}>
                        <img src={HiIcon} alt="hi" />
                        <div className={styles.title}>AI对话</div>
                    </div>
                    <div className={styles.historyBox}>
                        <div className={styles.recentChat}>
                            <img src={RecentChatIcon} alt="recent chat" />
                            <div className={styles.recentChatTitle}>最近对话</div>
                        </div>
                        <div
                            className={styles.createDefaultChatBtn}
                            onClick={createNewChat}
                        >
                            + 新建聊天
                        </div>
                        <div className={styles.historyList}>
                            {chatHistoryList.map((item: any) => (
                                <div
                                    key={item.id}
                                    onClick={() => handleChatItemClick(item)}
                                    className={styles.historyItem}
                                    style={{
                                        backgroundColor: currentChatInfo.id === item.id ? "#EEF2F6" : "",
                                    }}
                                >
                                    {item.work_info.chatList.length > 0 ? item.work_info.chatList[0].rawContent : "新对话"}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.agentBox}>
                        <div className={styles.intelligentAgent}>
                            <img src={IntelligentAgentIcon} alt="intelligent agent" />
                            <div className={styles.intelligentAgentTitle}>我的智能体</div>
                        </div>
                        <div className={styles.agentList}>
                            {agentList.map((item: any) => (
                                <div
                                    key={item.id}
                                    onClick={() => handleChatItemClick(item)}
                                    className={styles.agentItem}
                                    style={{
                                        backgroundColor: currentChatInfo.id === item.id ? "#EEF2F6" : "",
                                    }}
                                >
                                    {
                                        item.work_info.agentAvatar ?
                                            <img src={item.work_info.agentAvatar} className={styles.agentAvatar} /> :
                                            <div className={styles.agentAvatar} />
                                    }
                                    {item.work_info.agentName}
                                </div>
                            ))}
                        </div>
                        <div
                            className={styles.createAgentBtn}
                            onClick={openCreateAgentModal}
                        >
                            创建AI智能体
                        </div>
                    </div>
                </div>

                <div
                    className={styles.exitBtn}
                    onClick={() => navigate("/playground")}
                >
                    退出AI对话
                </div>
            </div>
            <div className={styles.chatPage}>
                <ChatPage chatInfo={currentChatInfo} />
                {!isModifyAgentConfigOpen && currentChatInfo.work_type === "playground_agent" && (
                    <div className={styles.modifyAgentConfigBtn} onClick={() => setIsModifyAgentConfigOpen(true)}>
                        <img src={currentChatInfo.work_info.agentAvatar || IntelligentAgentIcon} alt="intelligent agent" />
                    </div>
                )
                }
                {isModifyAgentConfigOpen && currentChatInfo.work_type === "playground_agent" && (
                    <ModifyAgentConfig
                        modifyItem={currentChatInfo}
                        setIsModifyAgentConfigOpen={setIsModifyAgentConfigOpen}
                        getAllAgents={getAllAgents}
                    />
                )}
            </div>
            <CreateAgentModal
                isCreateAgentModalOpen={isCreateAgentModalOpen}
                setIsCreateAgentModalOpen={setIsCreateAgentModalOpen}
                newMd5={genNewAgentMd5()}
                agentList={agentList}
                setAgentList={setAgentList}
                setCurrentChatInfo={setCurrentChatInfo}
            />
        </div>
    )
}

export default observer(AIChat);