import React, { useState, useEffect } from 'react';
import styles from './modifyAgentConfig.module.less';
import { ChatMessage } from '@/base/ChatMessage';
import UploadMedia from '@/components/UploadMedia/UploadMedia';
import UploadAgentAvatarIcon from '@/assets/img/playground/chat_upload_avatar.svg';
import AddPresetQuestionIcon from '@/assets/img/playground/add_preset_question.svg';
import { Select, message } from 'antd';
import { updateUserWork } from "@/api/userWork";
import AIChatRegionContent from "@/base/ElementData/AIChatRegionContent";
import CancelIcon from '@/assets/img/cuowu.svg';

const voiceList = [
    { value: "BV700_streaming", name: "默认-灿灿" },
    { value: "BV705_streaming", name: "炀炀" },
    { value: "BV034_streaming", name: "知性姐姐" },
    { value: "BV102_streaming", name: "儒雅青年" },
    { value: "BV007_streaming", name: "亲切女生" },
    { value: "BV113_streaming", name: "甜宠少御" },
    { value: "BV056_streaming", name: "阳光男生" },
    { value: "BV051_streaming", name: "奶气萌娃" },
    { value: "BV005_streaming", name: "活泼女生" },
    { value: "BV701_streaming", name: "擎苍-有声阅读" },
    { value: "BV033_streaming", name: "温柔小哥" },
    { value: "BV001_streaming", name: "通用女声" },
    { value: "BV002_streaming", name: "通用男声" }
];

interface ModifyAgentConfigProps {
    modifyItem: any,
    setIsModifyAgentConfigOpen: (isOpen: boolean) => void,
    getAllAgents: () => void,
}

const ModifyAgentConfig: React.FC<ModifyAgentConfigProps> = ({
    modifyItem,
    setIsModifyAgentConfigOpen,
    getAllAgents,
}) => {
    console.log(modifyItem);
    const [agentName, setAgentName] = useState(modifyItem.work_info?.agentName || '');
    const [agentSystemPrompt, setAgentSystemPrompt] = useState(modifyItem.work_info?.systemPrompt || '');
    const [agentAvatar, setAgentAvatar] = useState(modifyItem.work_info?.agentAvatar || '');
    const [headerText, setHeaderText] = useState(modifyItem.work_info?.headerText || '');
    const [presetQuestions, setPresetQuestions] = useState<ChatMessage[]>(modifyItem.work_info?.presetMessages || []);
    const [ttsVoice, setTtsVoice] = useState(modifyItem.work_info?.ttsVoice || 'BV700_streaming');
    const [isStream, setIsStream] = useState(modifyItem.work_info?.isStream || false);

    useEffect(() => {
        setAgentName(modifyItem.work_info?.agentName || '');
        setAgentSystemPrompt(modifyItem.work_info?.systemPrompt || '');
        setAgentAvatar(modifyItem.work_info?.agentAvatar || '');
        setHeaderText(modifyItem.work_info?.headerText || '');
        setPresetQuestions(modifyItem.work_info?.presetMessages || []);
        setTtsVoice(modifyItem.work_info?.ttsVoice || 'BV700_streaming');
        setIsStream(modifyItem.work_info?.isStream || false);
    }, [modifyItem])

    const handleAddPresetQuestion = () => {
        const newPresetQuestion = new ChatMessage({});
        setPresetQuestions([...presetQuestions, newPresetQuestion]);
    }

    const updateAgent = async () => {
        if (!agentName) {
            message.error('请输入智能体名称');
            return;
        }
        const newWorkInfo = (new AIChatRegionContent({
            systemPrompt: agentSystemPrompt,
            agentName: agentName,
            agentAvatar: agentAvatar,
            headerText: headerText,
            presetMessages: presetQuestions,
            ttsVoice: ttsVoice,
            chatList: modifyItem.work_info?.chatList || [],
            isStream: isStream
        })).toJSON();
        const newAgent = {
            ...modifyItem,
            user_name: modifyItem.user_name || '',
            work_info: newWorkInfo
        }
        try {
            const res = await updateUserWork(newAgent);
            if (res.status === 'success') {
                message.success('修改成功');
                getAllAgents();
                setIsModifyAgentConfigOpen(false);
            }
        } catch (error) {
            message.error('修改失败');
        }
    }

    const onCancel = () => {
        setIsModifyAgentConfigOpen(false);
        setAgentName('');
        setAgentSystemPrompt('');
        setAgentAvatar('');
        setHeaderText('');
        setPresetQuestions([]);
        setTtsVoice('BV700_streaming');
        setIsStream(false);
    }


    return (
        <div className={styles.modifyAgentConfigBox}>
            <div className={styles.cancelIcon} onClick={onCancel}>
                <img src={CancelIcon} />
            </div>
            <div className={styles.content}>
                <div className={styles.mainTitle}>修改AI智能体配置</div>
                <div className={styles.agentAvatarBox}>
                    <img src={agentAvatar} className={styles.agentAvatar} />
                    <div className={styles.uploadAvatar}>
                        <UploadMedia
                            successCallBack={(url) => {
                                setAgentAvatar(url);
                            }}
                            icon={UploadAgentAvatarIcon}
                            accept_str="image/*"
                            dir="avatar"
                            isMultiple={false}
                        />
                    </div>
                </div>
                <div className={styles.agentNameBox}>
                    <div className={styles.agentNameTitle}>智能体名称</div>
                    <input
                        type="text"
                        className={styles.agentNameInput}
                        placeholder="请输入智能体名称"
                        value={agentName}
                        onChange={(e) => setAgentName(e.target.value)}
                    />
                </div>
                <div className={styles.agentDescriptionBox}>
                    <div className={styles.agentDescriptionTitle}>系统提示词</div>
                    <textarea
                        className={styles.agentDescriptionInput}
                        placeholder="请输入系统提示词"
                        value={agentSystemPrompt}
                        onChange={(e) => setAgentSystemPrompt(e.target.value)}
                    />
                </div>
                <div className={styles.agentNameBox}>
                    <div className={styles.agentNameTitle}>头部标题</div>
                    <input
                        type="text"
                        className={styles.agentNameInput}
                        placeholder="请输入头部标题"
                        value={headerText}
                        onChange={(e) => setHeaderText(e.target.value)}
                    />
                </div>
                <div className={styles.addPresetQuestionsBox}>
                    <div className={styles.addPresetQuestionsTitle}>
                        <span>添加预设问题</span>
                        <div
                            className={styles.addPresetQuestionsBtn}
                            onClick={handleAddPresetQuestion}
                        >
                            <img src={AddPresetQuestionIcon} />
                            添加
                        </div>
                    </div>
                    <div className={styles.presetQuestionsList}>
                        {presetQuestions.map((question, index) => (
                            <div key={question.id} className={styles.presetQuestion}>
                                <div className={styles.presetQuestionTitle}>预设问题{index + 1}</div>
                                <input
                                    type="text"
                                    className={styles.presetQuestionInput}
                                    placeholder="请输入问题"
                                    value={question.rawContent}
                                    onChange={(e) => {
                                        question.rawContent = e.target.value;
                                        setPresetQuestions([...presetQuestions]);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.voiceSelectBox}>
                    <div className={styles.voiceSelectTitle}>AI回答音色</div>
                    <Select
                        value={ttsVoice}
                        style={{ width: 120 }}
                        onChange={(value) => setTtsVoice(value)}
                        className={styles.voiceSelect}
                    >
                        {voiceList.map((item) => (
                            <Select.Option key={item.value} value={item.value}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div className={styles.streamBox}>
                    <div className={styles.streamTitle}>流式回答</div>
                    <Select
                        value={isStream}
                        onChange={(value) => setIsStream(value)}
                        className={styles.streamSelect}
                    >
                        <Select.Option value={true}>
                            是
                        </Select.Option>
                        <Select.Option value={false}>
                            否
                        </Select.Option>
                    </Select>
                </div>
            </div>
            <div className={styles.createAgentBtnBox}>
                <div
                    className={styles.createAgentBtn}
                    onClick={updateAgent}
                >
                    保存修改
                </div>
            </div>
        </div>
    )
}

export default ModifyAgentConfig;
