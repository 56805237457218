import React, { useState } from 'react';
import { Modal, message, Select } from 'antd';
import styles from './createAgentModal.module.less';
import { createUserWork } from '@/api/userWork';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import AIChatRegionContent from "@/base/ElementData/AIChatRegionContent";
import DataDefinition from "@/base/DataDefinition";
import { useNavigate } from 'react-router-dom';
import UploadMedia from '@/components/UploadMedia/UploadMedia';
import UploadAgentAvatarIcon from '@/assets/img/playground/chat_upload_avatar.svg';
import AddPresetQuestionIcon from '@/assets/img/playground/add_preset_question.svg';
import { ChatMessage } from '@/base/ChatMessage';

const voiceList = [
    { value: "BV700_streaming", name: "默认-灿灿" },
    { value: "BV705_streaming", name: "炀炀" },
    { value: "BV034_streaming", name: "知性姐姐" },
    { value: "BV102_streaming", name: "儒雅青年" },
    { value: "BV007_streaming", name: "亲切女生" },
    { value: "BV113_streaming", name: "甜宠少御" },
    { value: "BV056_streaming", name: "阳光男生" },
    { value: "BV051_streaming", name: "奶气萌娃" },
    { value: "BV005_streaming", name: "活泼女生" },
    { value: "BV701_streaming", name: "擎苍-有声阅读" },
    { value: "BV033_streaming", name: "温柔小哥" },
    { value: "BV001_streaming", name: "通用女声" },
    { value: "BV002_streaming", name: "通用男声" }
];

interface CreateAgentModalProps {
    isCreateAgentModalOpen: boolean;
    setIsCreateAgentModalOpen: (isOpen: boolean) => void;
    newMd5: string;
    agentList: any[];
    setAgentList: (list: any[]) => void;
    setCurrentChatInfo: any
}

const CreateAgentModal: React.FC<CreateAgentModalProps> = ({
    isCreateAgentModalOpen,
    setIsCreateAgentModalOpen,
    newMd5,
    agentList,
    setAgentList,
    setCurrentChatInfo
}) => {
    const navigate = useNavigate();
    const { userInfoStore } = useStores();
    const [agentName, setAgentName] = useState('');
    const [agentSystemPrompt, setAgentSystemPrompt] = useState('');
    const [agentAvatar, setAgentAvatar] = useState('');
    const [headerText, setHeaderText] = useState('');
    const [presetQuestions, setPresetQuestions] = useState<ChatMessage[]>([]);
    const [ttsVoice, setTtsVoice] = useState('BV033_streaming');
    const [isStream, setIsStream] = useState(false);

    const createNewAgent = async (md5: string) => {
        if (!agentName) {
            message.error('请输入智能体名称');
            return;
        }
        try {
            const newChatAgent = {
                id: 0,
                md5: md5,
                user_id: userInfoStore.userInfoData.id,
                user_name: userInfoStore.userInfoData.name,
                work_type: "playground_agent",
                work_info: (new AIChatRegionContent({
                    systemPrompt: agentSystemPrompt,
                    agentName: agentName,
                    agentAvatar: agentAvatar,
                    headerText: headerText,
                    presetMessages: presetQuestions,
                    ttsVoice: ttsVoice,
                    isStream: isStream
                })).toJSON(),
                create_time: 0
            };

            const createRes: any = await createUserWork(newChatAgent);
            if (createRes.status === 'success') {
                createRes.data.work_info = new AIChatRegionContent(DataDefinition.toCamelCaseObj(createRes.data.work_info));
                setAgentList([createRes.data, ...agentList]);
                setCurrentChatInfo(createRes.data);
                navigate(`/playground/chat/${createRes.data.md5}`);
                setIsCreateAgentModalOpen(false);
                setAgentName('');
                setAgentSystemPrompt('');
                setAgentAvatar('');
                setHeaderText('');
                setPresetQuestions([]);
                setTtsVoice('BV033_streaming');
                setIsStream(false);
            }

        } catch (error) {
            message.error('发生错误，请重试');
            throw error;
        }
    };

    const handleAddPresetQuestion = () => {
        const newPresetQuestion = new ChatMessage({});
        setPresetQuestions([...presetQuestions, newPresetQuestion]);
    }

    const onCancel = () => {
        setIsCreateAgentModalOpen(false);
        setAgentName('');
        setAgentSystemPrompt('');
        setAgentAvatar('');
        setHeaderText('');
        setPresetQuestions([]);
        setTtsVoice('BV033_streaming');
        setIsStream(false);
    }
    return (
        <Modal
            centered
            open={isCreateAgentModalOpen}
            onCancel={onCancel}
            footer={null}
            className={styles.createAgentModal}
        >
            <div className={styles.createAgentBox}>
                <div className={styles.content}>
                    <div className={styles.mainTitle}>创建AI智能体</div>
                    <div className={styles.agentAvatarBox}>
                        <img src={agentAvatar} className={styles.agentAvatar} />
                        <div className={styles.uploadAvatar}>
                            <UploadMedia
                                successCallBack={(url) => {
                                    setAgentAvatar(url);
                                }}
                                icon={UploadAgentAvatarIcon}
                                accept_str="image/*"
                                dir="avatar"
                                isMultiple={false}
                            />
                        </div>
                    </div>
                    <div className={styles.agentNameBox}>
                        <div className={styles.agentNameTitle}>智能体名称</div>
                        <input
                            type="text"
                            className={styles.agentNameInput}
                            placeholder="请输入智能体名称"
                            value={agentName}
                            onChange={(e) => setAgentName(e.target.value)}
                        />
                    </div>
                    <div className={styles.agentDescriptionBox}>
                        <div className={styles.agentDescriptionTitle}>系统提示词</div>
                        <textarea
                            className={styles.agentDescriptionInput}
                            placeholder="请输入系统提示词"
                            value={agentSystemPrompt}
                            onChange={(e) => setAgentSystemPrompt(e.target.value)}
                        />
                    </div>
                    <div className={styles.agentNameBox}>
                        <div className={styles.agentNameTitle}>头部标题</div>
                        <input
                            type="text"
                            className={styles.agentNameInput}
                            placeholder="请输入头部标题"
                            value={headerText}
                            onChange={(e) => setHeaderText(e.target.value)}
                        />
                    </div>
                    <div className={styles.addPresetQuestionsBox}>
                        <div className={styles.addPresetQuestionsTitle}>
                            <span>添加预设问题</span>
                            <div
                                className={styles.addPresetQuestionsBtn}
                                onClick={handleAddPresetQuestion}
                            >
                                <img src={AddPresetQuestionIcon} />
                                添加
                            </div>
                        </div>
                        <div className={styles.presetQuestionsList}>
                            {presetQuestions.map((question, index) => (
                                <div key={question.id} className={styles.presetQuestion}>
                                    <div className={styles.presetQuestionTitle}>预设问题{index + 1}</div>
                                    <input
                                        type="text"
                                        className={styles.presetQuestionInput}
                                        placeholder="请输入问题"
                                        value={question.rawContent}
                                        onChange={(e) => {
                                            question.rawContent = e.target.value;
                                            setPresetQuestions([...presetQuestions]);
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.voiceSelectBox}>
                        <div className={styles.voiceSelectTitle}>AI回答音色</div>
                        <Select
                            value={ttsVoice}
                            onChange={(value) => setTtsVoice(value)}
                            className={styles.voiceSelect}
                        >
                            {voiceList.map((item) => (
                                <Select.Option key={item.value} value={item.value}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className={styles.streamBox}>
                        <div className={styles.streamTitle}>流式回答</div>
                        <Select
                            value={isStream}
                            onChange={(value) => setIsStream(value)}
                            className={styles.streamSelect}
                        >
                            <Select.Option value={true}>
                                是
                            </Select.Option>
                            <Select.Option value={false}>
                                否
                            </Select.Option>
                        </Select>
                    </div>
                </div>
                <div className={styles.createAgentBtnBox}>
                    <div
                        className={styles.createAgentBtn}
                        onClick={() => createNewAgent(newMd5)}
                    >
                        创建
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default observer(CreateAgentModal);
