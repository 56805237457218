import DataDefinition from "./DataDefinition";

class SongData extends DataDefinition {
    id: number;
    name: string;
    authorId: number;
    lyrics: string;
    musicUrl: string;
    coverUrl: string;
    tags: string;
    playCount: number;
    likeCount: number;
    updateTime: number;

    constructor({
        id = 0,
        name = "",
        authorId = 0,
        lyrics = "",
        musicUrl = "",
        coverUrl = "",
        tags = "",
        playCount = 0,
        likeCount = 0,
        updateTime = 0
    }: Partial<SongData> = {}) {
        super();
        this.allowedFields = ['id', 'name', 'authorId', 'lyrics', 'musicUrl', 'coverUrl',
            'tags', 'playCount', 'likeCount', 'updateTime'];
        this.id = id;
        this.name = name;
        this.authorId = authorId;
        this.lyrics = lyrics;
        this.musicUrl = musicUrl;
        this.coverUrl = coverUrl;
        this.tags = tags;
        this.playCount = playCount;
        this.likeCount = likeCount;
        this.updateTime = updateTime;
    }
};

export default SongData;
