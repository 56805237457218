import styles from './basicSharePage.module.less';
import { useMediaQuery } from 'react-responsive';
import BasicSharePageInfo from '@/base/ShareData/BasicSharePageInfo';
import OrangeBackgroundPC from '/assets/img/basic_background_pc_orange.svg';
import OrangeBackgroundMobile from '/assets/img/basic_background_orange.svg';
import BlueBackgroundPC from '/assets/img/basic_background_pc_blue.svg';
import BlueBackgroundMobile from '/assets/img/basic_background_blue.svg';
import MarkdownWithHighlight from '@/components/MarkdownWithHighlight/MarkdownWithHighlight';

interface BasicSharePageProps {
    basicSharePageInfo: BasicSharePageInfo;
}

const BasicSharePage: React.FC<BasicSharePageProps> = ({ basicSharePageInfo }) => {
    // 使用 useMediaQuery 钩子来检测屏幕宽度  
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isNotMobile = useMediaQuery({ query: '(min-width: 769px)' });

    console.log("OrangeBackgroundPC", OrangeBackgroundPC);

    return (
        <div
            className={styles.page}
            style={{
                backgroundImage: `url(${basicSharePageInfo?.backgroundStyle === 'orange' ? OrangeBackgroundPC : BlueBackgroundPC})`,
            }}
        >
            {isNotMobile && <div className={styles.tabletDesktopLayout}>
                <div className={styles.content}>
                    <div className={styles.imgBox}>
                        <img src={basicSharePageInfo.imgUrl} className={styles.image} />
                    </div>
                    <div className={styles.textArea}>
                        <div className={styles.title}>{basicSharePageInfo.title}</div>
                        <div className={styles.textContent}>
                            <MarkdownWithHighlight content={basicSharePageInfo.body} />
                        </div>
                    </div>
                </div>
            </div>}
            {
                isMobile && <div
                    className={styles.mobileLayout}
                    style={{
                        backgroundImage: `url(${basicSharePageInfo?.backgroundStyle === 'orange' ? OrangeBackgroundMobile : BlueBackgroundMobile})`,
                    }}
                >
                    <div className={styles.containerBox}>
                        <div className={styles.title}>
                            {basicSharePageInfo.title}
                        </div>
                        <div className={styles.imgBox}>
                            <img src={basicSharePageInfo.imgUrl} className={styles.image} />
                        </div>
                        <div className={styles.textContent}>
                            <MarkdownWithHighlight content={basicSharePageInfo.body} />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default BasicSharePage;
