import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { Outlet } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { Modal, Input, Button } from 'antd';
import UserManagementAPI from '@/api/userManagement';
import UserInfoData from '@/base/UserInfoData';
import UserAssetData from '@/base/UserAssetData';

const CheckUserName: React.FC = observer(() => {
    const userAPI = new UserManagementAPI();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [username, setUsername] = useState('');
    const { userInfoStore } = useStores();
    useEffect(() => {
        const isLoggedIn = Boolean(localStorage.getItem('token'));
        if (!isLoggedIn) return;

        userInfoStore.refreshUserInfo().then(() => {
            if (!userInfoStore.userInfoData.name) {
                // 尝试调getNickname接口
                const api = new UserManagementAPI();
                api.generateNickName().then((res) => {
                    setUsername(res.data.nickname);
                    setIsModalOpen(true);
                }).catch(() => {
                    console.error('Error getting nickname');
                });
            }
        }).catch(() => {
            console.error('Error refreshing user info');
        });

        return () => {
            userInfoStore.resetUserInfo();
        }
    }, [])

    const handleSubmit = () => {
        userInfoStore.setUserInfoData({ name: username });
        // 调用updateUser接口
        const api = new UserManagementAPI();
        const data = new UserInfoData({
            id: userInfoStore.userInfoData.id,
            name: username
        });

        api.updateUser(data).then(() => {
            setIsModalOpen(false);
        }).catch(() => {
            setIsModalOpen(false);
            console.error('Error updating user');
        });

    };

    const createUserAssets = async () => {
        let data = {
            user_id: 0,
            points: -1,
            stars: -1,
            extras: {},
            update_time: 0
        };

        try {
            const response = await userAPI.createUserAssets(data);
        } catch (error) {
            console.error('Error create user assets');
        }
    }

    useEffect(() => {
        if (userInfoStore.userInfoData.id) {
            userAPI.getUserAssets(userInfoStore.userInfoData.id).then(res => {
                if (res.status === 'error' && res.data === 'user not existed') {
                    createUserAssets();
                } else if (res.status === 'success') {
                    userInfoStore.updateUserAssetsFromData(res.data);
                }
            })
        }
    }, [userInfoStore.userInfoData.id])

    return (
        <>
            <Outlet />
            <Modal open={isModalOpen}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        disabled={!username}
                        onClick={handleSubmit}
                    >
                        确认
                    </Button>,
                ]}
            >
                <h2>请设定用户名</h2>
                <Input
                    placeholder="输入用户名"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />

            </Modal>
        </>
    )
})
export default CheckUserName;