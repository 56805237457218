import { genUUIDv4 } from '@/utils/id_generator';
import MusicIcon from '@/assets/img/playground/playground_music.svg';
import ChatIcon from '@/assets/img/playground/playground_chat.svg';

const getPlayGroundList = () => {
    return [
        {
            id: genUUIDv4(),
            name: "音乐制作",
            introduce: "用AI制作属于你的音乐",
            coverImg: MusicIcon,
            path: "/playground/bobo-music",
        },
        {
            id: genUUIDv4(),
            name: "AI聊天",
            introduce: "和AI聊天，让AI成为你的好朋友",
            coverImg: ChatIcon,
            path: `/playground/chat`,
        },
        //{
        //    id: genUUIDv4(),
        //    name: "AI聊天WS测试",
        //    introduce: "和AI聊天，让AI成为你的好朋友",
        //    coverImg: ChatIcon,
        //    path: "/playground/ai-chat-ws",
        //},
    ]
}

export default getPlayGroundList;
